import React, { useState } from 'react';
import './App.css';
import About from './About';
import Portfolio from './Portfolio';
import Contact from './Contact';
import ThemeContext from './ThemeContext';

function App() {

  const [darkMode, setDarkMode] = useState(false);

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <div className="App">
        <header className="header">
          <nav>
            <ul>
              <li><a href="#">About</a></li>
              <li><a href="#portfolio">Portfolio</a></li>
              <li><a href="#contact">Contact</a></li>
              <li><a href="https://github.com/CracklyDuck"></a></li>
              {/*<li>
                <button onClick={() => setDarkMode(!darkMode)}>
                  Toggle dark mode
                </button>
              </li>*/}
            </ul>
          </nav>
        </header>
        <main>
          <section id="about">
            <About />
          </section>
          <section id="portfolio">
            <Portfolio />
          </section>
          <section id="contact">
            <Contact />
          </section>
        </main>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
