import React from 'react';
import './Portfolio.css';

function Portfolio() {
  return (
    <section className="portfolio">
      <h2>Portfolio</h2>
      <div className="project-grid">
        <div className="project">
          <h3>Style Transfer</h3>
          <p>This project uses the pre-trained VGG19 model and transfer learning to generate stylized images.</p>
          <img src="/project-images/eiffel_tower.gif" alt="Project 1" />
        </div>
        <div className="project">
          <h3>Fruit Classification</h3>
          <p>Convulational Neural Network to classify an image between 9 fruit categories. With Keras and Tensorflow.</p>
          <img src="/project-images/fruit_classification.png" alt="Project 2" />
        </div>
        <div className="project">
          <h3>Transfer Learning</h3>
          <p>Flower type classification using InceptionV3 model with transfer learning.</p>
          <img src="/project-images/flower_recognition.png" alt="Project 3" />
        </div>
        
        {/* Add additional project components as needed */}
      </div>
    </section>
  );
}

export default Portfolio;