import React from 'react';
import './Contact.css';
import githubLogo from './images/github.svg';
import linkedinLogo from './images/linked-in.svg';
import mailLogo from './images/mail.svg';
import cvlogo from './images/cv.svg';

function Contact() {
  return (
    <section className="contact">
      <h2>Social Media</h2>
      <div className="social-media">
        <a href="mailto:me@fabiantrejo.com" target="_blank" rel="noopener noreferrer">
          <img src={mailLogo} alt="Mail" />
        </a>
        <a href="https://www.linkedin.com/in/fabiantrejo/" target="_blank" rel="noopener noreferrer">
          <img src={linkedinLogo} alt="LinkedIn" />
        </a>
        <a href="https://github.com/CracklyDuck" target="_blank" rel="noopener noreferrer">
          <img src={githubLogo} alt="GitHub" />
        </a>
        <a href="/Fabian_Trejo_Resume.pdf" target="_blank" rel="noopener noreferrer">
          <img src={cvlogo} alt="CV" />
        </a>
      </div>
    </section>
  );
}

export default Contact;