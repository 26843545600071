import React from 'react';
import './About.css';
import profileImage from './profile.jpg';

function About() {
  return (
    <section className="about">
      <img src={profileImage} alt="Profile" />
      <h2>Fabian Trejo</h2>
      <p>I'm a computer science student specializing in data science, machine learning, and cloud computing with Microsoft Azure. I have four years of experience in software development, with proficiency in Python, SQL, R, and CUDA. I hold certificates from Microsoft Azure, NVIDIA, and IBM. I'm enthusiastic about applying my skills to solve real-world problems using data and cloud technologies.</p>
    </section>
  );
}

export default About;